<template>
  <v-container fluid>
    <report-title :title="options.title" class="mb-2" />
    <report-filter @change-filter="fetchReport($event)" />
    <report-table :headers="options.headers" :items="options.data" />
    <report-alert />
  </v-container>
</template>

<script >
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "ReportUsersActivity",
  components: {
    ReportTitle: () => import("@/components/report/ReportTitle.vue"),
    ReportFilter: () => import("@/components/report/ReportFilter.vue"),
    ReportTable: () => import("@/components/report/ReportTable.vue"),
    ReportAlert: () => import("@/components/report/ReportAlert.vue"),
  },
  computed: {
    options: get("report/optionsUsersActivity"),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      resetReport: "report/resetUsersActivityReport",
      fetchReport: "report/fetchUsersActivityData",
    }),
  },
  mounted() {
    this.resetReport();
  },
});
</script>